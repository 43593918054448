import React from "react";
import StickyTitle from "./sticky-title";
import ArticlePreview from "./article-preview";

import * as styles from "./article-preview-grid.module.css";

function ArticlePreviewGrid(props) {
  return (
    <div>
      {props.title && (
        <StickyTitle title={props.title} />
      )}
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id}>
              <ArticlePreview {...node} />
            </li>
          ))}
      </ul>
    </div>
  );
}

export default ArticlePreviewGrid;