import { Link } from "gatsby";
import { format } from "date-fns";
import React from "react";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockText from "./block-text";

import * as styles from "./article-preview.module.css";

function ArticlePreview(props) {
  return (
    <Link to={`/news/${props.slug.current}`}>
      <div className={styles.thumb}>
        {props.featuredImage && props.featuredImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.featuredImage))
              .width(1440)
              .height(Math.floor((9 / 16) * 1440))
              .url()}
            alt={props.featuredImage.alt}
          />
        )}
      </div>
      <div className={cn(styles.text)}>
        <h3 className={cn(styles.title)}>{props.title}</h3>
        <p>{format(new Date(props.publishedAt), "D MMMM YYYY")}</p>
        {props._rawExcerpt && (
          <div className={styles.excerpt}>
            <BlockText blocks={props._rawExcerpt} />
          </div>
        )}
      </div>
    </Link>
  );
}

export default ArticlePreview;