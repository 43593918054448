import React, { useContext } from "react";
import { cn } from "../lib/helpers";
import ScrollContext from "../context/ScrollContext";

import * as styles from "./sticky-title.module.css";

const StickyTitle = (props) => {
  const scroll = useContext(ScrollContext);

  return (
    <div className={cn(styles.title, scroll.scrollDir === 'scrolling down' ? styles.scrollingDown : '')}>
      <h2>{props.title}</h2>
    </div>
  );
};

export default StickyTitle;