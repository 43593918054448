import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import { ScrollProvider } from "../context/ScrollContext";
import ArticlePreviewGrid from "../components/article-preview-grid";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";

export const query = graphql`
  query NewsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    articles: allSanityArticle(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          _rawExcerpt
          featuredImage {
            _key
            asset {
              _id
            }
            crop {
              _key
              _type
              bottom
              left
              right
              top
            }
            hotspot {
              _key
              _type
              height
              width
              x
              y
            }
          }
          publishedAt
          slug {
            current
          }
          title
        }
      }
    }
  }
`;

const NewsPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const articleNodes = (data || {}).articles
    ? mapEdgesToNodes(data.articles)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <ScrollProvider>
        <SEO title={site.title} description={site.description} keywords={site.keywords} />
        {articleNodes && (
          <ArticlePreviewGrid
            title="News & Press"
            nodes={articleNodes}
          />
        )}
      </ScrollProvider>
    </Layout>
  );
};

export default NewsPage;
